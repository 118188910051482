import 'idempotent-babel-polyfill'; // eslint-disable-line
// import $ from 'jquery';
// import 'bootstrap-datepicker';
import moment from 'moment-mini';

document.addEventListener('DOMContentLoaded', () => {
  // init datepicker
  // $.fn.datepicker; // eslint-disable-line
  // $('[data-toggle="datepicker"]').datepicker({
  //   autoclose: true,
  //   maxViewMode: 2,
  //   format: 'mm/dd/yyyy',
  //   startDate: '-60y',
  //   endDate: '-2y',
  // });

  // remove alerts after five seconds
  window.setTimeout(() => {
    const e = document.querySelector('div.alert');
    if (e) e.parentNode.removeChild(e);
  }, 5000);

  // register page
  const inputs = document.querySelectorAll('.input');
  if (inputs && inputs.length > 0) {
    const data = Array.from(inputs);
    data.forEach((input) => {
      input.addEventListener('focus', () => {
        input.parentElement.classList.add('focus');
      });
    });
  }

  // take test buttons
  const takeTestBtns = document.querySelectorAll('button.dob-confirm-btn');
  if (takeTestBtns && takeTestBtns.length > 0) {
    const data = Array.from(takeTestBtns);
    data.forEach((test) => {
      test.addEventListener('click', () => {
        const dob = test.getAttribute('data-dob');
        const child = test.getAttribute('data-child');
        const subscription = test.getAttribute('data-subscription');
        const name = test.getAttribute('data-name');
        const defaultModule = test.getAttribute('data-defaultModule');
        const pack = test.getAttribute('data-pack');

        if (defaultModule === 'any') {
          const { labels: labelsData } = window;
          const labels = labelsData.filter(l => l._package.toString() === pack);
          if (labels.length > 0) {
            const target = document.querySelector('div#anyDefaultModule');
            const h4 = document.createElement('h4');
            target.innerHTML = '';
            h4.textContent = 'Select Test Module';
            target.appendChild(h4);
            labels.forEach((l) => {
              const input = document.createElement('input');
              input.setAttribute('type', 'radio');
              input.setAttribute('value', l._id);
              input.setAttribute('name', 'defaultModule');
              input.required = true;
              const span = document.createElement('span');
              span.textContent = ` ${l.name}`;
              span.setAttribute('class', 'text-capitalize');
              target.appendChild(input);
              target.appendChild(span);
              target.innerHTML += '&nbsp;&nbsp;';
            });
          }
        }

        document.querySelector('input#dob-subscription').setAttribute('value', subscription);
        document.querySelector('input#dob-child').setAttribute('value', child);
        document.querySelector('span#dob-name').textContent = name;
        if (dob) {
          const dateOfBirth = moment(dob, 'MM/DD/YYYY');
          document.querySelector('input#modalDay').value = dateOfBirth.date();
          document.querySelector('input#modalMonth').value = dateOfBirth.month() + 1;
          document.querySelector('input#modalYear').value = dateOfBirth.year();
        }
      });
    });
  }
});
